import { Button, Card, CardContent, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from '../Services/DomainService';
import { CreateLink, DeleteLink, UpdateLink } from '../Services/LinkService';

interface LinkFormProps {
    link: Link | null,
    resetLink: Function,
    reloadLinks: Function
}

const LinkForm = ({ link, resetLink, reloadLinks }: LinkFormProps) => {
    const [linkId, setLinkId] = useState<null | number>(null);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    useEffect(() => {
        if (link !== null) {
            setFrom(link.from);
            setTo(link.to);
            setLinkId(link.id);
        } else {
            setFrom('');
            setTo('');
            setLinkId(null);
        }
    }, [link, setFrom, setTo, setLinkId]);

    const handleFormSubmit = useCallback((e) => {
        if (!linkId) {
            CreateLink({
                from, to
            }).then(() => {
                reloadLinks();
            })
        } else {
            UpdateLink({
                id: linkId,
                from,
                to
            }).then(() => {
                reloadLinks();
            })
        }

        e.preventDefault();
    }, [from, to, reloadLinks]);

    const handleDelete = useCallback(() => {
        if (!linkId) return;
        DeleteLink(linkId).then(() => {
            reloadLinks();
        })
    }, [linkId, reloadLinks]);

    const handleReset = useCallback(() => {
        resetLink();
    }, [resetLink]);

    const onFromChange = useCallback((e) => {
        setFrom(e.target.value);
    }, [setFrom]);

    const onToChange = useCallback((e) => {
        setTo(e.target.value);
    }, [setTo]);

    return (
        <Card style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '100px' }}>
            <CardContent>
                <form style={{ display: 'grid', gap: '20px', width: '700px' }} onSubmit={ handleFormSubmit }>
                    <TextField id="From URL" disabled={!!linkId} label="From URL" value={from} onChange={onFromChange} required={true}/>
                    <TextField id="To URL" disabled={!!linkId} label="To URL" value={to} onChange={onToChange} required={true}/>
                    { linkId ?
                        <>
                            <Button variant="contained" onClick={handleDelete}>
                                Delete
                            </Button>
                            <Button variant="contained" onClick={handleReset}>
                                Reset
                            </Button>
                        </>
                    :
                        <Button variant="contained" type="submit">
                            { linkId ? 'Save' : 'Create' }
                        </Button>
                    }
                </form>
            </CardContent>
        </Card> 
    )
}

export default LinkForm;
