import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../Store';
import { Refresh } from '../Services/AuthService';
import { RefreshDomains } from "../Services/DomainService";
import { select } from "../Slices/Domain";
import Select from '@mui/material/Select';

export default function Navigation() {
    const page = useLocation();
    const go = useNavigate();
    const dispatch = useDispatch();

    const userId = useSelector((state: RootState) => state.user.id);
    const username = useSelector((state: RootState) => state.user.username);

    const domains = useSelector((state: RootState) => state.domain.domains); 
    const domain = useSelector((state: RootState) => state.domain.selected);

    React.useEffect(() => {
        if (userId === null && sessionStorage.getItem('user-token') !== null) {
            Refresh();
        }

        if (userId !== null) {
            if (domains.length === 0) {
                RefreshDomains();
            }

            if (domain === null && domains.length !== 0) {
                dispatch(select(domains[0]));
            }
        }

        if (page.pathname !== "/login") {
            if (userId === null) {
                go('/login');
            }
        } else {
            if (userId !== null) {
                go('/');
            }
        }
    }, [page, go, userId, domains, domain, dispatch]);

    const handleDomainChange = React.useCallback((event) => {
        dispatch(select(domains.find(item => item.id === event.target.value)));
    }, [dispatch, domains]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Shortlinks
                    </Typography>
                    { domains && domain ?
                        <Select
                            id="demo-simple-select"
                            value={domain?.id}
                            label="Domain"
                            onChange={handleDomainChange}
                        >
                            { domains.map(domain => {
                                return (
                                    <MenuItem key={`domain-${domain.id}`} value={domain.id}>{domain.name}</MenuItem>
                                );
                            })}
                        </Select>
                    : null }
                    <Typography variant="body1"component="div" style={{ marginLeft: '20px' }}>
                        { username }                        
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
