import { Button, Card, CardContent, TextField } from "@mui/material";
import React, { useCallback, useState } from "react";
import { Login as AuthLogin } from "../Services/AuthService";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [formEnabled, setFormEnabled] = useState(true);

    const onUsernameChange = useCallback((event) => {
        setUsername(event.target.value);
    }, [setUsername]);

    const onPasswordChange = useCallback((event) => {
        setPassword(event.target.value);
    }, [setPassword]);

    const handleFormSubmit = useCallback((event) => {
        if (formEnabled) {
            setFormEnabled(false);
            AuthLogin(username, password, setPassword, setFormEnabled);
        }
        event.preventDefault();
    }, [username, password, setPassword, formEnabled, setFormEnabled]);

    return (
        <Card style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', marginTop: '100px' }}>
            <CardContent>
                <form style={{ display: 'grid', gap: '20px', width: '200px' }} onSubmit={ handleFormSubmit }>
                    <TextField id="username" label="Username" value={username} onChange={onUsernameChange} required={true}/>
                    <TextField id="password" label="Password" value={password} onChange={onPasswordChange} type="password" required={true}/>
                    <Button variant="contained" type="submit">
                        Login
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
}

export default Login;
