import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLinks, Link } from "../Services/DomainService";
import { RootState } from "../Store";
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import LinkForm from "../Components/CreateForm";

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 100
    },
    { 
        field: 'from',
        headerName: 'From URL',
        flex: 1
    },
    {
        field: 'to',
        headerName: 'To URL',
        flex: 1
    }
];



const Index = () => {
    const domain = useSelector((state: RootState) => state.domain.selected);
    const initialLink: Link[] = [];
    const [links, setLinks] = useState(initialLink);
    const [link, setLink] = useState<Link | null>(null);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    useEffect(() => {
        const selectedLinkData = links.filter((link) => {
            return selectionModel[0] === link.id;
        });

        if (selectedLinkData.length !== 0) {
            setLink(selectedLinkData[0]);
        } else {
            setLink(null);
        }
    }, [selectionModel, links]);

    useEffect(() => {
        reloadLinks();
    }, [domain]);

    const reloadLinks = useCallback(() => {
        (async () => {
            if (domain) {
                setLinks(await getLinks(domain.id));
            }
        })();
    }, [domain]);

    const clear = useCallback(() => {
        setSelectionModel([]);
    }, []);

    return (
        <div>
            <div style={{  height: '400px', padding: '20px' }}>
                <DataGrid
                    rows={links}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    onSelectionModelChange={(newSelection) => {
                        setSelectionModel(newSelection);
                    }}
                    selectionModel={selectionModel}
                />
            </div>
            <LinkForm link={link} resetLink={clear} reloadLinks={reloadLinks}/>
        </div>
    );
}

export default Index;
