import Request from "../Request";
import Store from "../Store"
import { Link, LinkContent } from "./DomainService"

const UpdateLink = (link: Link): Promise<void> => {
    return Promise.resolve();
}

const CreateLink = (linkData: LinkContent): Promise<void> => {
    const domainId = Store.getState().domain.selected?.id;

    if (domainId) {
        return Request(true).post(`/domains/${domainId}`, linkData);
    }

    return Promise.resolve();
}

const DeleteLink = (id: number): Promise<void> => {
    const domainId = Store.getState().domain.selected?.id;

    if (domainId) {
        return Request(true).delete(`/domains/${domainId}/${id}`);
    }

    return Promise.resolve();
}

export {
    UpdateLink,
    CreateLink,
    DeleteLink
}
