import { createSlice } from "@reduxjs/toolkit";

export interface Domain {
    name: string,
    id: number
}

export interface DomainState {
    domains: Domain[],
    selected: Domain | null
}

const initialDomains: DomainState = {
    domains: [],
    selected: null,
}

const domainSlice = createSlice({
    name: 'domains',
    initialState: initialDomains,
    reducers: {
        select: (state, action) => {
            state.selected = action.payload;

            return state;
        },
        setState: (state, action) => {
            state.domains = action.payload;

            return state;
        }
    }
})

export const { select, setState } = domainSlice.actions;
export default domainSlice.reducer;
