import Store from "../Store";
import { setUser, UserState } from "../Slices/User";
import Request from "../Request";

const Login = (username: string, password: string, setPassword: (arg0: string) => void, setFormEnabled: (arg0: boolean) => void) => {
    Request(false).post('/auth/login', {username, password}).then(response => {
        console.log(response);

        const user: UserState = {
            id: response.data.user.id,
            username: response.data.user.username,
            token: response.data.access_token,
        }

        Store.dispatch(setUser(user));
    }).catch(error => {
        setPassword('');
        setFormEnabled(true);
    });
}

const Logout = () => {

}

let refreshing = false;
const Refresh = () => {
    if (!refreshing) {
        refreshing = true;
        Request(true).get('/auth/refresh').then(response => {
            const user: UserState = {
                id: response.data.user.id,
                username: response.data.user.username,
                token: response.data.access_token,
            }

            Store.dispatch(setUser(user));
        }).catch(error => {
            sessionStorage.removeItem('user-token');
        }).finally(() => {
            refreshing = false;
        })
    }
}

export {
    Login, Logout, Refresh
};
