import Store from "../Store";
import { setState } from "../Slices/Domain";
import Request from "../Request";

const RefreshDomains = () => {
    Request(true).get('/domains').then(response => {
        Store.dispatch(setState(response.data));
    }).catch(error => {
        Store.dispatch(setState([]));
    });
}

export interface LinkContent {
    from: string,
    to: string,
}

export interface Link extends LinkContent {
    id: number,
    data?: LinkData[],
}

interface LinkData {
    month: string,
    clicks: number
}

const getLinks = async (domainId: number): Promise<Link[]> => {
    const response = await Request(true).get(`/domains/${domainId}`);
    return response.data.links;
}

export { RefreshDomains, getLinks };
