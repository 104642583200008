import { combineReducers, configureStore } from "@reduxjs/toolkit";
import Domain from "./Slices/Domain";
import User from "./Slices/User";

const reducers = combineReducers({ user: User, domain: Domain });

const Store = configureStore({
    reducer: reducers
});

export default Store;
export type RootState = ReturnType<typeof reducers>;
