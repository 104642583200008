import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navigation from './Components/Nav';
import Index from './Pages/Index';
import Login from './Pages/Login';

function App() {
  return (
    <BrowserRouter>
      <Navigation/>
      <Routes>
        <Route path='/login' element={<Login />}/>
        <Route path='/' element={<Index />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
