import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
    token: string | null,
    id: number | null,
    username: string | null
}

const initialUser: UserState = {
    token: null,
    id: null,
    username: null
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialUser,
    reducers: {
        setUser: (state, payload) => {
            state = payload.payload;
            sessionStorage.setItem('user-token', payload.payload.token);

            return state;
        }
    }
})

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
